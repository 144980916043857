import axios from 'axios';
import { Notification, MessageBox, Message, Loading } from 'element-ui';
import { getToken, setToken, removeToken } from '@/utils/auth'
import store from '@/store'
// import qs from 'qs'

// // 环境的切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'https://zckj.zhccpt.com/';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'https://zckj.zhccpt.com/';
// }
const errorCode = {
    401: '认证失败，无法访问系统资源',
    403: '当前操作没有权限',
    404: '访问资源不存在',
    default: '系统未知错误，请反馈给管理员',
};

const TokenKey = 'admin-Token';

// 只执行一次的函数
const once = (fn) => {
    let caller = true;
    return (...args) => {
        if (caller) {
            caller = false;
            fn.apply(this, args);
        }
    };
};

// // 弹框标识，防止多次弹框
// let alerted = false

// // 登录超时
// const timeout = once(() => {
//     MessageBox.confirm('你已被登出，请重新登录', '系统提示', {
//         confirmButtonText: '重新登录',
//         cancelButtonText: '取消',
//         type: 'warning',
//     }).then(() => {
//         // 登录超时
//         // window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`
//         removeToken()
//         alerted = false
//         location.reload() // 为了重新实例化vue-router对象 避免bug
//     }).catch(() => {
//         alerted = false
//     });
// });

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
const api = window.location.protocol + "//" + window.location.host; // 动态获取当前url作为请求域名   

const instance = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // 测试环境:'http://192.168.1.5'
    // baseURL: 'https://zckj.zhccpt.com/',
    // baseURL: 'http://192.168.1.15:9102', // 后端本地地址
    // baseURL: process.env.VUE_APP_BASE_URL,
    baseURL: api,
    // 超时
    timeout: 50000,
});

// const getToken = () => sessionStorage.getItem('TokenKey');

let pop = false
let count = 0 // 请求接口次数

// request请求拦截器
instance.interceptors.request.use((config) => {
    // if (getToken()) {
    //     console.log(getToken());
    //     // eslint-disable-next-line no-param-reassign
    //     config.headers.Authorization = `Bearer ${getToken()}`;
    // }
    if (store.getters.token) {
        config.headers['Authorization'] = getToken() ? getToken() : store.getters.token
        if (!getToken) {
            setToken(store.getters.token)
        }
    }
    count++
    if (!pop) {
        // Loading.service({
        //     fullscreen: true,
        //     lock: true,
        //     text: '加载数据中...',
        //     spinner: 'el-icon-loading',
        //     customClass: 'load',
        //     // background: 'rgba(255, 255, 255, 0.1)'
        //     background: 'rgba(000, 000, 000, 0.3)'
        // })
        store.commit('setLoading', true)
        // console.log(store.state.data.loading)
        pop = true
    }
    // get方法传递数组的处理（重点代码）
    // if (config.method === 'get') {
    //     config.paramsSerializer = function(params) {
    //         console.log(params);
    //         return qs.stringify(params, { arrayFormat: 'comma' })
    //     }
    // }
    return config;
});

// 弹框标识，防止多次弹框
let alerted = false

// response响应拦截器
instance.interceptors.response.use((res) => {
    count--
    if (pop && count === 0) {
        store.commit('setLoading', false)
        // console.log(store.state.data.loading)
        // setTimeout(() => {
        //     Loading.service({
        //         fullscreen: true,
        //         lock: true,
        //         text: '加载数据中...',
        //         spinner: 'el-icon-loading',
        //         customClass: 'load',
        //         // background: 'rgba(255, 255, 255, 0.1)'
        //         background: 'rgba(000, 000, 000, 0.3)'
        //     }).close()
        //     pop = false
        // }, 100)
        pop = false
    }
    // 未设置状态码则默认成功状态
    const code = Number(res.status || 200);
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode.default;
    if (code === 200 || code === 0) {
        return res.data;
    }
    if (code === 401) {
        // if (!alerted) {
        //     alerted = true
        //     timeout();
        // }
    } else if (code === 403) {
        Notification.error({
            title: msg,
        });
        return Promise.reject(new Error('error'));
    } else {
        Message({
            message: msg,
            type: 'success',
            iconClass: 'el-icon-error',
        });
        return Promise.reject(new Error(msg));
    }
    return res;
}, (error) => {
    count--
    if (pop && count === 0) {
        store.commit('setLoading', false)
        // console.log(store.state.data.loading)
        // setTimeout(() => {
        //     Loading.service({
        //         fullscreen: true,
        //         lock: true,
        //         text: '加载数据中...',
        //         spinner: 'el-icon-loading',
        //         customClass: 'load',
        //         // background: 'rgba(255, 255, 255, 0.1)'
        //         background: 'rgba(000, 000, 000, 0.3)'
        //     }).close()
        //     pop = false
        // }, 100)
        pop = false
    }
    // 抛出错误，为了部分接口返回需要的错误信息
    console.log(error);
    if (error.response.status === 401) {
        // timeout();
        if (!alerted) {
            alerted = true
            MessageBox.confirm('你已被登出，请重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                // 登录超时
                // 这里写清除token的代码
                // store.commit('LogOut') // 登出
                // window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`
                removeToken()
                alerted = false
                location.reload() // 为了重新实例化vue-router对象 避免bug
            }).catch(() => {
                alerted = false
            });
        }
    }
    // return error.response.data
    return Promise.reject(error.response.data)
});


export const { request } = instance.request;

export const get = (url, params = {}, base = {}) => instance.get(url, { params, ...base })

export const post = (url, data = {}, base = {}) => instance.post(url, data, { ...base })

export default instance